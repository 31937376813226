import React, { useEffect, useState, useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import { debounce } from "lodash";
import { Row, Col, Button, Popconfirm } from "antd";
import { PlateEditor } from "@udecode/plate";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { InputBase } from "@material-ui/core";
import { DeleteOutlined } from "@ant-design/icons";

import useRootStore from "../../../store/useRootStore";
import { getPresentableChapterType } from "../../../utils/initials";
import { SceneUtils } from "../../../utils/scene/sceneServices";
import { AddImageIcon } from "../../../content/icons";
import Can from "../../casl/Can";
import JSONPreviewer from "../../Shared/JSONPreviewer";
import IndividualChapterImageUpload from "../../Shared/Uploads/IndividualChapterImage";
import { ChapterConfiguration } from "./ChapterConfiguration";
import { can } from "../../casl/helpers";

const isDev = process.env.REACT_APP_NODE_ENV === "development";

interface TitlebarProps {
  onEnter: any;
  placeholder: string;
  autofocus: boolean;
  editor?: PlateEditor | null;
  isFullPageImage?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnGroup: {
      marginRight: `-${theme.spacing(0.5)}px`,
      marginLeft: `-${theme.spacing(0.5)}px`,
      padding: 0,
      marginTop: theme.spacing(0.5),

      "& > *": {
        textTransform: "capitalize",
      },
    },
    title: {
      width: "100%",
      fontSize: 21,
      fontWeight: 600,

      "& input": {
        paddingBottom: 0,
      },
    },
    subtitle: {
      width: "100%",
      fontSize: 15,
      fontWeight: 500,

      "& input": {
        height: 32,
        padding: 0,
      },
    },
  })
);

export const Titlebar = observer((props: TitlebarProps) => {
  const {
    onEnter,
    autofocus,
    placeholder,
    isFullPageImage = false,
    editor,
  } = props;
  const {
    debouncedSaveChapterMetaUpdates,
    saveChapterMetaUpdates,
    chapterTemplate,
    debouncedSyncChapterTemplateChangesToServer,
  } = useRootStore().bookStore;
  const { currentScene, chapterMeta: currentChapter } =
    useRootStore().chapterStore;
  const { chapterTemplateView } = useRootStore().appStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { setChapterScenes, sceneCache } = useRootStore().sideMenuStore;
  const { setIsChapterLocalUpdate, setChangeEvent } =
    useRootStore().bookSyncWebSocketStore;
  const classes = useStyles();

  const [sceneTitle, setSceneTitle] = useState("");
  const [chapterTitle, setChapterTitle] = useState("");
  const [chapterSubtitle, setChapterSubtitle] = useState("");
  const [chapterTemplateTitle, setChapterTemplateTitle] = useState("");
  const [chapterTemplateSubtitle, setChapterTemplateSubtitle] = useState("");
  const [chapterImageUrl, setChapterImageUrl] = useState("");
  const [showImgDialog, setShowImgDialog] = useState(false);

  useEffect(() => {
    setChapterTitle(currentChapter.title);
  }, [currentChapter.title]);
  useEffect(() => {
    setChapterSubtitle(currentChapter.subtitle || "");
  }, [currentChapter.subtitle]);
  useEffect(() => {
    setChapterTemplateTitle(chapterTemplate.title);
  }, [chapterTemplate.title]);
  useEffect(() => {
    setChapterTemplateSubtitle(chapterTemplate.subtitle || "");
  }, [chapterTemplate.subtitle]);
  useEffect(() => {
    setChapterImageUrl(currentChapter.image);
  }, [currentChapter.image]);

  useEffect(() => {
    setSceneTitle(currentScene?.scene.sceneTitle || "");
  }, [currentScene?.scene]);

  const isInScene = useMemo(() => {
    return currentScene && currentScene.chapterId === currentChapter._id;
  }, [currentChapter._id, currentScene]);

  const titleFieldValue = useMemo(() => {
    if (isInScene) return sceneTitle;
    if (chapterTemplateView) return chapterTemplateTitle;
    return chapterTitle;
  }, [
    isInScene,
    sceneTitle,
    chapterTemplateView,
    chapterTemplateTitle,
    chapterTitle,
  ]);

  const subtitleFieldValue = useMemo(() => {
    if (chapterTemplateView) return chapterTemplateSubtitle;
    return chapterSubtitle;
  }, [chapterTemplateSubtitle, chapterTemplateView, chapterSubtitle]);

  const shouldShowChapterType =
    !chapterTemplateView && currentChapter.type !== "chapter";
  const shouldShowChapterImage =
    !isFullPageImage &&
    currentChapter.type !== "image" &&
    currentChapter.type !== "copyrights" &&
    !chapterTemplateView &&
    !isInScene;
  const shouldShowChapterSubtitle =
    !isFullPageImage &&
    currentChapter.type !== "image" &&
    currentChapter.type !== "copyrights" &&
    !isInScene;
  const shouldShowJSONPreviewer = isDev && !isFullPageImage;

  const debounceSetSceneTitleInEditorAndSidebar = useCallback(
    debounce((title: string) => {
      /** update the plate nodes with the new title */
      if (!editor) return;
      if (!currentScene) return;
      SceneUtils.updateEditorSceneTitle(editor, currentScene, title);
      /**
       * update the sidebar;
       * this wont automatically refresh since scene normalizer only runs
       * on ornamental break changes
       */
      const currentChapterScenes = sceneCache.get(currentChapter._id);
      if (currentChapterScenes?.length) {
        const updatedScenes = currentChapterScenes.map((scene) => {
          if (scene.sceneIndex === currentScene?.scene.sceneIndex) {
            return {
              ...scene,
              sceneTitle: title,
            };
          }
          return scene;
        });
        setChapterScenes(currentChapter._id, updatedScenes);
      }
    }, 500),
    [editor, currentScene]
  );

  const chapterTitleOnChange = (title: string) => {
    setChapterTitle(title);
    setIsChapterLocalUpdate(true);
    setChangeEvent("chapter-title-change");
    if (chapterTemplateView) {
      debouncedSyncChapterTemplateChangesToServer(chapterTemplate._id, {
        title,
      });
    } else {
      debouncedSaveChapterMetaUpdates({
        ...currentChapter,
        title,
      });
      refreshCache(currentChapter.bookId, "chapter-title-change", {
        "chapter-title-change": { chapterId: currentChapter._id },
      });
    }
  };

  const chapterSubtitleOnChange = (subtitle: string) => {
    setChapterSubtitle(subtitle);
    setIsChapterLocalUpdate(true);
    setChangeEvent("chapter-subtitle-change");
    if (chapterTemplateView) {
      debouncedSyncChapterTemplateChangesToServer(chapterTemplate._id, {
        subtitle,
      });
    } else {
      debouncedSaveChapterMetaUpdates({
        ...currentChapter,
        subtitle,
      });
      refreshCache(currentChapter.bookId, "chapter-subtitle-change", {
        "chapter-subtitle-change": { chapterId: currentChapter._id },
      });
    }
  };

  const sceneTitleOnChange = (sceneTitle: string) => {
    setSceneTitle(sceneTitle);
    debounceSetSceneTitleInEditorAndSidebar(sceneTitle);
  };

  const chapterImageOnChange = (imageUrl: string) => {
    setChapterImageUrl(imageUrl);
    setShowImgDialog(false);
    setIsChapterLocalUpdate(true);
    setChangeEvent("chapter-contents-change");
    saveChapterMetaUpdates({
      ...currentChapter,
      image: imageUrl,
    });
    refreshCache(currentChapter.bookId, "chapter-contents-change", {
      "chapter-contents-change": { chapterId: currentChapter._id },
    });
  };

  return (
    <Row className="att-editor-bar">
      <Row gutter={8} align="middle">
        {/** Render chapter type above title for special chapter types */}
        {shouldShowChapterType && (
          <Col>
            <h4 className="chapter-type-toolbar">
              {getPresentableChapterType(currentChapter.type)}
            </h4>
          </Col>
        )}
      </Row>
      <Row
        className={`title-bar-wrapper ${
          !isFullPageImage ? "title-bar-padding" : ""
        }`}
        align="middle"
      >
        {shouldShowChapterImage &&
          (chapterImageUrl ? (
            <Col>
              <div
                className="title-bar-image-previewer"
                style={{ backgroundImage: `url(${chapterImageUrl})` }}
              >
                <div className="title-bar-image-previewer-container">
                  <Popconfirm
                    title="Are you sure you want to delete this image?"
                    onConfirm={() => chapterImageOnChange("")}
                    okButtonProps={{
                      danger: true,
                    }}
                    okText="Delete"
                    cancelText="No"
                  >
                    <Button
                      size="middle"
                      danger
                      type="primary"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                </div>
              </div>
            </Col>
          ) : (
            <Col>
              <Can action={"view"} subject={"add-chapter-image"} passThrough>
                {(allowed) => (
                  <a
                    onClick={() =>
                      allowed ? setShowImgDialog(!showImgDialog) : null
                    }
                  >
                    <div className="title-bar-image-container">
                      <p>{allowed}</p>
                      <AddImageIcon />
                    </div>
                  </a>
                )}
              </Can>
            </Col>
          ))}

        <Col flex={1}>
          <div
            className={`tite-bar-title-input ${
              isFullPageImage ? "tite-bar-title-input-chapter-settings" : ""
            }`}
          >
            <InputBase
              type="text"
              value={titleFieldValue}
              disabled={!can("view", "chapter-title-edit")}
              placeholder={placeholder}
              autoFocus={autofocus}
              className={classes.title}
              inputProps={{ "aria-label": "naked" }}
              onChange={(event) =>
                isInScene
                  ? sceneTitleOnChange(event.target.value)
                  : chapterTitleOnChange(event.target.value)
              }
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  onEnter();
                }
              }}
            />

            {
              /** chapter level configuration for full page images */
              isFullPageImage && <ChapterConfiguration variant={"image"} />
            }
          </div>
          <div>
            {shouldShowChapterSubtitle && (
              <InputBase
                type="text"
                disabled={!can("view", "chapter-subtitle-edit")}
                value={subtitleFieldValue}
                placeholder={"Add subtitle"}
                className={classes.subtitle}
                inputProps={{ "aria-label": "naked" }}
                onChange={(e) => chapterSubtitleOnChange(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onEnter();
                  }
                }}
              />
            )}
          </div>
        </Col>
        <div className="title-bar-icon-holder">
          <Col>
            <div>
              <Row>
                {
                  /** chapter level configuration for normal chapters */
                  !isInScene && !isFullPageImage && (
                    <ChapterConfiguration variant={"default"} />
                  )
                }
              </Row>
            </div>
          </Col>
          <Col>
            <Row gutter={10}>
              {shouldShowJSONPreviewer && (
                <Col>
                  <JSONPreviewer />
                </Col>
              )}
            </Row>
          </Col>
        </div>
      </Row>
      <IndividualChapterImageUpload
        show={showImgDialog}
        handler={chapterImageOnChange}
      />
    </Row>
  );
});
